@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400&display=swap");

body {
  background-color: #efefef;
  margin: 0;
  padding: 0;
  width: 100%;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: large;
  /* line-height: 1.5em; */
}

.iframe {
  overflow-y: hidden;
  height: 85vh;
}

.content-loading {
  height: auto;
  max-height: auto;
  padding-left: 1em;
  padding-bottom: 2em;
}

.content {
  max-width: 1200px;
  height: auto;
  max-height: auto;
  padding-left: 1em;
  padding-bottom: 2em;
}

.tools-content {
  width: 1600px;
  height: auto;
  max-height: auto;
  padding-left: 1em;
  padding-bottom: 2em;
}

.border {
  border: none;
  height: 0.1em;
}

.grid-container {
  display: grid;
}

/* .grid-container--fit {
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
} */

.grid-container--fill {
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  margin-left: 65px;
  row-gap: 1.2em;
  column-gap: 1.7em;
}

.grid-element {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 95px;
  height: 95px;
  padding: 10px;
  text-align: center;
  background-color: white;
  border-radius: 7px;
  box-shadow: 10px 10px 20px -10px rgba(0, 0, 0, 0.75);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  font-size: medium;
  font-weight: 500;
  user-select: none;
}

.grid-element:hover {
  transform: scale(1.1);
  box-shadow: 15px 15px 20px -20px rgb(64, 64, 64);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.grid-element:active {
  transform: scale(0.9);
  box-shadow: 15px 15px 20px -20px rgb(64, 64, 64);
  transition: transform 0.1s ease, box-shadow 0.1s ease;
}

a {
  text-decoration: none;
  color: #000;
}

h2 {
  font-size: x-large;
}

img {
  display: flex;
  margin: 0 auto;
  width: 3.5em;
}

.pagename {
  margin-top: 0;
  margin-left: 65px;
  margin-bottom: 11px;
  color: #013e74;
  user-select: none;
}

.pagename:nth-child(3) {
  margin-top: 16px;
}

.loginbutton {
  margin: 0 60px;
  padding-top: 1em;
}

.search-box {
  width: 30%;
  padding: 5px;
  margin-top: 8px;
  font-size: 17px;
  border-radius: 5px;
  border: 1px solid;
  color: #013e74;
  text-indent: 8px;
}

.code-box {
  width: 25%;
  padding: 9px;
  margin-top: 8px;
  font-size: 17px;
  border-radius: 5px;
  border: 1px solid;
  color: #013e74;
}

.search-button {
  /* float: right; */
  padding: 5px 19px;
  margin-top: 8px;
  margin-left: 8px;
  margin-right: 16px;
  background: #ddd;
  font-size: 17px;
  font-weight: 600;
  border: 1px solid;
  cursor: pointer;
  color: #013e74;
  border-radius: 5px;
}

.code-button {
  width: 18em;
  padding: 5px 19px;
  margin-top: 18px;
  margin-right: 16px;
  background: #ddd;
  font-size: 17px;
  font-weight: 600;
  border: 1px solid;
  cursor: pointer;
  color: #013e74;
  border-radius: 5px;
}

.columns {
  display: flex;

  justify-content: left; /* Zorgt ervoor dat de kolommen gelijkmatig over de ruimte worden verdeeld */
  gap: 20px; /* Ruimte tussen de kolommen */
}

.column {
  flex: 0; /* Zorgt ervoor dat de kolommen even breed zijn */
}

.temporary-password-list,
.backup-codes-list {
  list-style-type: none;
  padding: 0;
  width: 18em;
}

.temporary-password-list li,
.backup-codes-list li {
  padding: 5px;
  border: 1px solid #ccc;
  margin: 5px 0;
  font-size: 16px;
  background-color: white;
}

.temporary-password-placeholder,
.backup-code-placeholder {
  height: 20px;
  background-color: white;
  border: 1px dashed #ccc;
}

.sub-content {
  height: fit-content;
  width: fit-content;
  padding-bottom: 1em;
  color: #013e74;
  margin: 0 60px;
  margin-top: 10px;
  background-color: #ffffff;
  /* border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px; */
  box-shadow: 10px 10px 20px -10px rgba(0, 0, 0, 0.75);
}

.text-container {
  padding-left: 1.5em;
  padding-top: 0.7em;
  padding-right: 1.5em;
}

.search-container {
  font-size: 17px;
  height: fit-content;
  width: 80%;
  padding-bottom: 1em;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-style: normal;
  color: #013e74;
  margin: 0 60px;
  margin-top: 30px;
}

.user-details {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-style: normal;
  color: #013e74;
  margin: 0 5px;
  margin-top: 20px;
  line-height: 25px;
}

.red-text {
  color: crimson;
}

.links-list {
  list-style-type: none; /* Remove default list styling */
  padding: 0; /* Remove default padding */
  margin: 0 60px;
  margin-top: 30px;
}

.links-list li {
  margin-bottom: 10px; /* Space between buttons */
}

.link-button {
  display: block; /* Make the links fill the container */
  width: 30%; /* Ensure full width for easier clicking */
  padding: 10px; /* Padding for aesthetics */
  background-color: #013e74; /* Example button color */
  color: white; /* Text color */
  text-align: center; /* Center the text */
  text-decoration: none; /* Remove underline from links */
  border-radius: 5px; /* Optional: Rounded corners for buttons */
  font-weight: bolder;
}

.link-button:hover {
  background-color: crimson; /* Darker shade on hover */
  color: #013e74; /* Text color */
}

.custom-progress-bar {
  background: linear-gradient(to right, #afca0b, #053d71);
}

/* Beperk de grootte van de toast */
.Toastify__toast {
  max-width: 350px; /* Beperk de breedte */
  font-size: 0.9rem; /* Pas de tekstgrootte aan */
  font-family: "Roboto", sans-serif;
  background-color: white;
}

/* Zorg ervoor dat de container correct is gepositioneerd */
.Toastify__toast-container {
  z-index: 9999; /* Zorg dat de toast boven andere elementen staat */
  width: auto; /* Stel de breedte automatisch in */
  padding: 0; /* Verwijder overbodige padding */
}

/* Pas het uiterlijk van links in de toast aan */
.Toastify__toast a {
  color: crimson; /* Kleur voor de link */
  text-decoration: underline; /* Onderstreepte links */
  cursor: pointer; /* Wijzig cursor naar pointer */
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Media query for screens 1344px and smaller */
@media (max-width: 1550px) {
  .content {
    min-height: 65vh;
    max-height: auto;
  }
}
