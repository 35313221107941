@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400&display=swap");

:root {
  --zz-white: white;
  --zz-backgroundhgrey: #efefef;
  --zz-backgroundgreen: #f2f8de;
  --zz-backgroundred: #b38686;
  --zz-green: #afca0b;
  --zz-bookbuttongreen: #03a848;
  --zz-bookbuttongreen-hover: #5bad7d;
  --zz-blue: #013e74;
  --zz-red: #b12424;
  --zz-yellow: #fafc60;
  --zz-black: black;
}

footer {
  background-color: rgb(255, 255, 255);
  text-align: center;
  position: fixed;
  bottom: 0;
  width: 100%;
  box-shadow: 0px 5px 21px -7px rgba(0, 0, 0, 0.75);
  z-index: 1000;
}

.footer-container {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block;
  font-size: medium;
  color: var(--zz-blue);
  opacity: 0;
  transition: opacity 1s ease-in-out;
  padding: 0.4em;
}

.footer-container.fade-in {
  opacity: 1;
}

.footer-container.fade-out {
  opacity: 0;
}
