@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400&display=swap");

.menu {
  background-color: rgb(255, 255, 255); /* Transparante achtergrond */
  height: auto;
  display: block;
  align-items: center; /* Verticale centrering */
  padding: 3px 78px 8px;
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-size: medium;
  box-shadow: 0px 5px 21px -7px rgba(0, 0, 0, 0.75);
  user-select: none;
}

.menu ul {
  margin: 0;
  padding: 0;
  /* padding-top: 0.3em; */
  list-style: none; /* Verwijder bullet points */
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: larger;
}

.menu li {
  display: inline-block; /* Items naast elkaar */
  margin: 0 8px; /* Margins tussen items */
}

.menu li a {
  text-decoration: none;
  color: #013e74;
}

.menu .logo-container {
  display: flex;
  align-items: center;
  padding-left: 0.5rem;
}

.menu .floatright {
  float: inline-end;
  color: #013e74;
  list-style: none;
  font-family: "Roboto", sans-serif;
  font-size: large;
}

.menu .zzlogo {
  width: 4.5em;
  margin-right: 6px;
  margin-left: -6px;
}


.menu .name {
  font-weight: bold;
  color: #013e74;
}

@media (max-width: 550px) {
  .menu {
    height: 20vh;
  }

  .menu .floatright {
    padding-top: 5px;
    display: block;
    float: left;
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-style: normal;
    margin: 0 10px;
    font-size: medium;
  }
}
